define("discourse/plugins/discourse-user-post-count/discourse/widgets/user-post-count", ["exports", "discourse/widgets/render-glimmer", "discourse/widgets/widget", "@ember/template-factory"], function (_exports, _renderGlimmer, _widget, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("user-post-count", {
    html(attrs) {
      return new _renderGlimmer.default(this, "div.user-post-user__container", (0, _templateFactory.createTemplateFactory)(
      /*
        <UserPostCount @count={{ @data.count }} />
      */
      {
        "id": "o0I42tTQ",
        "block": "[[[8,[39,0],null,[[\"@count\"],[[30,1,[\"count\"]]]],null]],[\"@data\"],false,[\"user-post-count\"]]",
        "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-user-post-count/discourse/widgets/user-post-count.js",
        "isStrictMode": false
      }), {
        count: this.siteSettings.user_post_count_include_topic ? attrs.user_post_count + attrs.user_topic_count : attrs.user_post_count
      });
    }
  });
});