define("discourse/plugins/discourse-user-post-count/discourse/initializers/user-post-count", ["exports", "discourse/lib/api", "discourse/plugins/discourse-user-post-count/discourse/components/user-post-count"], function (_exports, _api, _userPostCount) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("0.8", api => {
    const siteSettings = api.container.lookup("service:site-settings");
    if (siteSettings.user_post_count_in_post) {
      api.includePostAttributes("user_post_count");
      api.includePostAttributes("user_topic_count");
      api.decorateWidget("poster-name:after", helper => {
        return helper.attach("user-post-count", helper.attrs);
      });
    }
    if (siteSettings.user_post_count_in_usercard) {
      api.renderInOutlet("user-card-post-names", _userPostCount.default);
    }
  });
});